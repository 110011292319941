<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <!-- Modal Jenis Customer -->

      <div
        class="modal fade"
        id="exampleModalKategoriBarang"
        tabindex="-1"
        aria-labelledby="exampleModalKategoriBarangLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalKategoriBarangLabel">JENIS CUSTOMER</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                class="pt-5"
                label="Search"
                @keyup="doSearchJenisCustomer()"
                v-model="searchJenisCustomer"
                dense
                outlined
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">JENIS CUSTOMER</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataJenisCustomer()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">{{ data.jenis_customer }}</td>

                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          v-on:click="pilihJenisCustomer(data.id_jeniscustomer, data.jenis_customer)"
                          class="me-3 mt-4"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- // doSearchJenisCustomer // displayDataJenisCustomer // setPagesJenisCustomer // paginateJenisCustomer -->
                            <span class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                @click="pageJenisCustomer--"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageJenisCustomer == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                            <span
                              :class="['v-data-footer', pageNumber == pageJenisCustomer ? 'active' : '']"
                              v-for="pageNumber in pagesJenisCustomer.slice(
                                pageJenisCustomer - 1,
                                pageJenisCustomer + 5,
                              )"
                              :key="pageNumber"
                              @click="pageJenisCustomer = pageNumber"
                            >
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </span>
                            <span class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageJenisCustomer < pagesJenisCustomer.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageJenisCustomer++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal Jenis Customer -->

      <!-- Modal Cabang -->
      <div
        class="modal fade"
        id="exampleModalCabang"
        tabindex="-1"
        aria-labelledby="exampleModalCabangLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCabangLabel">CABANG</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                class="pt-5"
                label="Search"
                @keyup="doSearch()"
                v-model="search"
                dense
                outlined
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAME CABANG</th>
                      <th class="text-center text-uppercase">AKTIF</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayData()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">{{ data.nama_cabang }}</td>
                      <td class="text-center">
                        <v-chip v-if="data.aktif == 'Y'" small color="info" class="font-weight-medium"> YA </v-chip>
                        <v-chip v-else small color="error" class="font-weight-medium"> TIDAK </v-chip>
                      </td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          v-on:click="pilihCabangs(data.id_cabang, data.nama_cabang)"
                          class="me-3 mt-4"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <span class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                @click="page--"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                            <span
                              :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                              v-for="pageNumber in pages.slice(page - 1, page + 5)"
                              :key="pageNumber"
                              @click="page = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </span>
                            <span class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page < pages.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="page++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal Cabang -->

      <!-- Modal Elektronik -->
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE CUSTOMER</v-card-title>
        <form @submit.prevent="CreateData" ref="form">
          <v-row>
            <v-col cols="12" v-for="error in errors" :key="error">
              <v-alert color="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    <!-- {{ icons.mdiAlertOutline }} -->
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-row>
              <v-col md="12" cols="12"
                ><br />

                <v-col md="9" cols="12">
                  <v-row no-gutters>
                    <v-col cols="3" md="3">
                      <div class="form-check form-switch mb-2">
                        <input
                          v-on:click="isHiddenCabang = !isHiddenCabang"
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                        />
                        <label class="form-check-label" for="flexSwitchCheckDefault">Cabang baru</label>
                      </div>
                    </v-col>
                    <v-col cols="4" md="9">
                      <v-text-field
                        v-if="isHiddenCabang"
                        label="Nama Cabang Baru"
                        v-model="customer.nama_cabang"
                        id="nama_cabang"
                        dense
                        outlined
                      ></v-text-field>
                      <v-text-field
                        widhth="40px"
                        v-if="!isHiddenCabang"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalCabang"
                        label="Nama Cabang"
                        v-model="customer.nama_cabang"
                        id="nama_cabang"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                      <text
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalCabang"
                        label="Nama Cabang"
                        v-model="customer.id_cabang"
                        id="id_cabang"
                        dense
                        outlined
                        readonly
                        type="hidden"
                      ></text>
                    </v-col>
                  </v-row>

                  <!-- Kat Barang -->
                  <v-row no-gutters>
                    <v-col cols="3" md="3">
                      <div class="form-check form-switch mb-2">
                        <input
                          v-on:click="isHiddenJenisCustomer = !isHiddenJenisCustomer"
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                        />
                        <label class="form-check-label" for="flexSwitchCheckDefault">Jenis Customer Baru</label>
                      </div>
                    </v-col>
                    <v-col cols="4" md="9">
                      <v-text-field
                        v-if="isHiddenJenisCustomer"
                        label="Jenis Customer Baru"
                        v-model="customer.jenis_customer"
                        id="jenis_customer"
                        dense
                        outlined
                      ></v-text-field>
                      <v-text-field
                        v-if="!isHiddenJenisCustomer"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalKategoriBarang"
                        label="Jenis Customer"
                        v-model="customer.jenis_customer"
                        id="jenis_customer"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                      <text
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalKategoriBarang"
                        label="Jenis Customer"
                        v-model="customer.id_jeniscustomer"
                        id="id_jeniscustomer"
                        dense
                        outlined
                        readonly
                        type="hidden"
                      ></text>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>

            <v-col md="8" cols="12">
              <v-text-field label="Nama Customer" v-model="customer.nama_customer" dense outlined></v-text-field>
              <v-text-field label="Telp" v-model="customer.telp" dense outlined></v-text-field>
              <v-text-field label="Email" v-model="customer.email" dense outlined></v-text-field>
              <v-text-field label="Alamat" v-model="customer.alamat" dense outlined></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-header>Aktif</v-header>
              <v-radio-group v-model="customer.aktif" column>
                <v-radio label="Ya" value="Y" color="info"></v-radio>
                <v-radio label="Tidak" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>
              <router-link :to="{ name: 'customer-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiDelete,
  mdiGreasePencil,
  mdiContentSave,
  mdiSlashForward,
  mdiCheckBold,
} from '@mdi/js'

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/superadmin/dashboard',
        },
        {
          text: 'Customer',
          disabled: false,
          href: '/superadmin/customer',
        },
        {
          text: 'Update Customer',
          disabled: true,
        },
      ],

      // icons
      icons: {
        mdiGreasePencil,
        mdiDelete,
        mdiContentSave,
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiSlashForward,
        mdiCheckBold,
      },

      isHiddenCabang: false,
      isHiddenJenisCustomer: false,

      customer: {
        id_cabang: '',
        id_jeniscustomer: '',
        nama_customer: '',
        telp: '',
        email: '',
        alamat: '',
        aktif: 'Y',
      },

      datacabangaktif: [],
      datajeniscustomer: [],
      errors: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],

      pageJenisCustomer: 1,
      perPageJenisCustomer: 10,
      pagesJenisCustomer: [],
      searchJenisCustomer: '',
      searchResultJenisCustomer: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.GetAllCabangs()
      this.getAllJenisCustomer()
      this.getAllUsers()
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    getAllUsers() {
      let uriUsers = process.env.VUE_APP_ROOT_API + `/api/superadmin/customer/${this.$route.params.id_customer}/show`
      this.axios.get(uriUsers).then(response => {
        this.customer = response.data.customer
        // console.log(this.users.jenisbarangs)
      })
    },

    getAllJenisCustomer() {
      //state token
      localStorage.getItem('token')
      let url = process.env.VUE_APP_ROOT_API + '/api/superadmin/jeniscustomer-index'
      return this.axios.get(url).then(response => {
        ;(this.datajeniscustomer = response.data.jeniscustomer), t
      })
    },

    CreateData(e) {
      console.log('CREATE' + this.customer.aktif)
      if (this.$data.customer.nama_customer.length === 0) {
        // this.errors.push('Deskripsi harus diisi !')

        this.$swal.fire({
          title: 'Error',
          text: 'Nama customer wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + `/api/superadmin/customer/${this.$route.params.id_customer}/update`
        this.axios
          .post(uri, {
            id_cabang: this.customer.id_cabang,
            id_jeniscustomer: this.customer.id_jeniscustomer,
            nama_customer: this.customer.nama_customer,
            telp: this.customer.telp,
            email: this.customer.email,
            alamat: this.customer.alamat,
            aktif: this.customer.aktif,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Customer update successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })
          })
          .catch(e => {
            this.$swal.fire({
              title: 'Error!',
              html: this.customer.nama_customer + ' sudah digunakan !',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'customer-index' })
        }, 3000)
      }
    },

    GetAllCabangs() {
      //state token
      localStorage.getItem('token')
      let url = process.env.VUE_APP_ROOT_API + '/api/superadmin/cabang-index'
      return this.axios.get(url).then(response => {
        this.datacabangaktif = response.data.cabangaktif
      })
    },

    //  Cabang
    doSearch() {
      let go = this.datacabangaktif.filter(item => {
        return this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_cabang.toLowerCase().includes(v))
      })
      this.searchResult = go
      this.pages = []
      this.setPages()
    },

    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.datacabangaktif)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.datacabangaktif.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(datacabangaktif) {
      let page = this.page
      let perPage = this.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return datacabangaktif.slice(from, to)
    },

    pilihCabangs(id_cabang, nama_cabang) {
      document.querySelector('#id_cabang').setAttribute('value', id_cabang)
      document.querySelector('#nama_cabang').setAttribute('value', nama_cabang)
      this.customer.id_cabang = id_cabang
      this.customer.nama_cabang = nama_cabang
    },
    // End Cabang
    // Jenis Customer
    doSearchJenisCustomer() {
      let go = this.datajeniscustomer.filter(item => {
        return this.searchJenisCustomer
          .toLowerCase()
          .split(' ')
          .every(v => item.jenis_customer.toLowerCase().includes(v))
      })
      this.searchResultJenisCustomer = go
      this.pagesJenisCustomer = []
      this.setPages()
    },

    displayDataJenisCustomer() {
      let paginate

      if (this.searchJenisCustomer == '') {
        paginate = this.paginate(this.datajeniscustomer)
      } else {
        paginate = this.paginate(this.searchResultJenisCustomer)
      }

      return paginate
    },
    setPagesJenisCustomer() {
      let numberOfPages

      if (this.searchJenisCustomer == '') {
        numberOfPages = Math.ceil(this.datajeniscustomer.length / this.perPageJenisCustomer)
      } else {
        numberOfPages = Math.ceil(this.searchResultJenisCustomer.length / this.perPageJenisCustomer)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesJenisCustomer.push(index)
      }
    },
    paginateJenisCustomer(datajeniscustomer) {
      let pageJenisCustomer = this.pageJenisCustomer
      let perPageJenisCustomer = this.perPageJenisCustomer
      let from = pageJenisCustomer * perPageJenisCustomer - perPageJenisCustomer
      let to = pageJenisCustomer * perPageJenisCustomer
      return datajeniscustomer.slice(from, to)
    },

    pilihJenisCustomer(id_jeniscustomer, jenis_customer) {
      document.querySelector('#id_jeniscustomer').setAttribute('value', id_jeniscustomer)
      document.querySelector('#jenis_customer').setAttribute('value', jenis_customer)
      this.customer.id_jeniscustomer = id_jeniscustomer
      this.customer.jenis_customer = jenis_customer
    },
  },
  watch: {
    cabang_rem() {
      this.pages = []
      this.setPages()
    },

    JenisCustomer_rem() {
      this.pageJenisCustomer = []
      this.setPages()
    },
  },
}
</script>
